import React from 'react'
import AboutNew from '../components/NewAbout'
import Footer from '../components/Footer'

function About() {
  return (
    <>
    <AboutNew />
    <Footer />
    </>
  )
}

export default About