import React from 'react'
import '../App.css'
import './About.css'
import {Link} from 'react-router-dom';
import food from '../images/ok.jpg'

function About() {
  return (
    <div className='whit'>
    <img src={food} className='gig'></img>
    <div className='aboutNew-container'>
    <h1>About Us</h1>
    <p>Infinitam partiales qui continere stabilire pro tum somniorum nec. Notatu sub vim nam juncta mentem nia sumpti. Sui facultate res vul abducerem objective inscitiae. Aliud locus se illam ullam et atqui. Ente co mo fato bere igni volo. Compages quaerere re scriptae aetheris to. Ea memores delusum dicamne si mo scripto visione ac.</p>
    <p>Id me formas ad genium ea semper. Pauciora re im ex tractant omnesque extensam scilicet formemus. Alicubi ego alienum ignotas agi. Ut longa re latum illae aliam primo. Exsurgit ita inveniri qua diversas qui vox. Fiat duce fore sane sibi ac ipse id. Pervenisse affirmabam persuadere falsitatis se at re eo. Si ea du discrimen voluntate suscipere judicarem ex experimur occurrent. Authorem creditum ostendam sui immorari ens.</p>
    
    <p>Mem totius remque uno omnium nam soleam omnino cogito. Dependent quaslibet iii vix antiquius. Ei suam mo an ulla homo suum.</p>
    </div>
    </div>
  )
}

export default About